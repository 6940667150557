<template>
  <v-list nav>
    <!-- Loop over items -->
    <div v-for="object in pageObjects" :key="object.title">
      <!-- If single page, uses definition below -->
      <v-list-item
        v-if="!object.isgroup"
        link
        color="primary"
        :to="object.path"
      >
        <v-list-item-icon v-if="object.icon">
          <v-icon>{{ object.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><textloader-component :textKey="object.path"/></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- If page group, use definition below -->
      <v-list-group
        v-if="object.isgroup"
        color="accent"
        :prepend-icon="object.icon"
      >
        <template v-slot:activator>
          <v-list-item-title><textloader-component :textKey="object.id"/></v-list-item-title>
        </template>
        <v-list-item
          v-for="page in object.subpages"
          :key="page.path"
          link
          v-on:click="website(page.path)"
          color="primary"
        >
          <v-list-item-content>
            <v-list-item-title><textloader-component :textKey="page.path"/></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </div>
    <v-list-item
    style="margin-bottom: 16px;"
        link
        color="primary"
        href="https://arbetsmarknad.futf.se"
        >
        <v-list-item-icon>
          <v-icon>mdi-office-building</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Arbetsmarknad
        </v-list-item-content>
      </v-list-item>

  </v-list>
</template>

<script>
import navdata from '@/assets/navdata.json'
import TextloaderComponent from '@/components/TextloaderComponent'

export default {
  computed: {
    pageObjects: function () {
      // Return a list of objects that are either single pages,
      // or a group of pages that has subpages listed at 'subpages'
      // property.

      // Read data from file

      // Place the pages that dont belong to a group in an array
      const pageObjects = navdata.pageinfo.filter((page) => page.group === '')
      pageObjects.forEach(page => { page.isGroup = false })

      // Place all page groups in an array
      var groupObjects = []
      for (const group of navdata.groupinfo) {
        const groupobject = {}
        groupobject.icon = group.icon
        groupobject.id = group.groupid
        groupobject.subpages = []
        groupobject.isgroup = true
        groupObjects.push(groupobject)
      }

      // Loop over all pages and add to approporiate group
      for (const page of navdata.pageinfo) {
        if (page.group !== '') {
          groupObjects.find(group => group.id === page.group).subpages.push(page)
        }
      }
      return pageObjects.concat(groupObjects)
    }
  },
  components: {
    TextloaderComponent
  },
  methods: {
    website: function (link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    }

  }
}
</script>
